@import './Colors.css';

.right{
     text-align: right;
}

.left{
    text-align: left;
}

.center{
    text-align: center;
}

.reveal-text{
    clip-path: polygon(0 0,100% 0, 100% 100%, 0% 100%);
    line-height: 5.9rem;
}

.side-title{
    font-family: "Montserrat", sans-serif; 
    color: var(--white);   

    font-weight: 400;
    font-size: 3.33rem;
}

.main-title{
    display: inline-flex;

    color: var(--white);
    font-family: "Montserrat", sans-serif;    

    font-weight: bold;
    font-size: 4rem;
}

.regular-title{
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 3px;
}

.mid-text{
    font-size: 18px;
    color: var(--white);
}

.small-title{
    display:inline-flex;
    color: var(--white);
    letter-spacing: 2px;
    font-size: 1.33rem;

    padding-bottom: 0.3rem;
    border-bottom: var(--blue) 3px solid;
}

.basic-text{
    color: var(--white);
    line-height: 175%;
    font-size: 17.5px;
}

.blue-line{
    border-bottom: var(--blue) solid 2px;
}

.secound-text{
    user-select: text;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.33rem;
    color: var(--white);

    padding-top: 0.5rem;
}

.error{
    color: red;
    position: absolute;
    right: 6.2rem;
    top: 3.7rem;
}

.ok{
    color: var(--blue);
    position: absolute;
    right: 4.4rem;
    top: 3.7rem;
}

.invisible-link{
    text-decoration: none;
}

@media (max-width: 930px) {
    .reveal-text{
        clip-path: polygon(0 0,100% 0, 100% 100%, 0% 100%);
        line-height: 6rem;
        font-size: 2rem;
    }

    .main-title{
        font-size: 3.3rem;
    }

}