@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../../styles/Colors.css';

* {

    padding: 0;
    margin: 0;
}

.custom-shape-divider-bottom-1712579962 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: -5;
}

.custom-shape-divider-bottom-1712579962 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 189px;
}

.custom-shape-divider-bottom-1712579962 .shape-fill {
    fill: #1A1919;
}


.header-container {
    
    position: sticky;
    top: 0;
    z-index: 1000;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: var(--white);
    background-color: var(--black);

    font-family: 'Roboto', sans-serif;
    font-size: 1.22rem;
    
    padding: 0.8rem 1.7rem 1.1rem 0.6rem;
}


.contact-wrapper {
    display: flex;
    gap: 0.5rem;
    background-color: var(--black);
    padding: 1rem 0;
}

ul {
    display: flex;
    flex-direction: none;
    background-color: var(--black);

    text-decoration: none;
}

ul button{
    border: none;
    margin: 0;
    padding: 0;
}

li {
    background-color: var(--black);
    list-style: none;
}

.link-wrapper {
    background-color: var(--black);

}

.link {
    padding: 1rem 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;

    text-decoration: none;
    color: var(--white);
    background-color: var(--black);

    user-select: none;
    transition: 500ms;
}

.link:hover {
    color: var(--blue);

    transition: 500ms;
    cursor: pointer;
}

.contact {
    font-family: "Roboto", sans-serif;

    color: var(--white);
    background-color: var(--black);
    padding: 0 1rem 0 0.2rem;
    font-weight: 400;
    font-size: 1.4rem;
}

#menuToggle input {
    display: none;
}

#menuToggle .bar {
    display: none;

}

/* phone */

.mobile{
    display: none;
}

.header-end{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:2rem;
}

.flag{
    height: 2rem;
    filter: saturate(0.1);
    transition: 500ms;
    padding-right: 1rem;
}
.flag:hover{
    cursor: pointer;
    transition: 500ms;
    filter: saturate(1.1);  
}

.contact .preset{
    color: var(--blue);
    font-weight: 300;
    padding-right: 0.2rem;
    font-size: calc(90%);
}

.mobile-container {
    display: none;
}

.flag-container-mobile {
    display: none;
}

@media (max-width: 930px) {

    .shape-fill{
        display: none;
    }

    .flag{
        scale: calc(90%);
        padding: 0.1rem;
    }

    .flag-container-mobile {
        display: flex;
        justify-content: left;
        padding-left: 1.8rem;
        padding-top: 1.5rem;
    }

    .flag-container {
        display: none;
    }


    #menuToggle {
        display: block;
        position: relative;
    
        z-index: 1;
    
        user-select: none;
    }
    
    
    li {
        background-color: var(--black);
    }
    
    .link {
        padding: 1rem;
        margin-left: 2rem;
    
        text-decoration: none;
        color: var(--white);
        background-color: var(--black);
    
        user-select: none;
        transition: 500ms;
    }
    
    .link:hover {
        color: var(--blue);
    
        transition: 500ms;
        cursor: pointer;
    }
    
    .contact-wrapper {
        display: none;
    }

    .contact{
        color: var(--white);
        background-color: var(--black);
        padding-left: 1rem;

    }

    .mobile-container{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding-top: 1rem;
        padding-left: 2rem;
    }

    .mobile{
        display: flex;
        gap: 0.5rem;
        font-size: calc(90%) ;
        background-color: var(--black);
    }

    .preset{
        display: flex;
        color: var(--blue);
        align-items: center;
        font-size: calc(90%);
        font-weight: 200;
    }

    #menuToggle input {
        display: block;
        width: 60px;
        height: 60px;

        margin: 0;
        top: -10px;
        left: 2px;
        position: absolute;

        cursor: pointer;

        opacity: 0;
        z-index: 2;
    }

    #menuToggle .bar {
        display: block;
        width: 54px;
        height: 8px;
        margin: 8px;
        position: relative;

        background-color: var(--white);

        border-radius: 5px;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;
    }

    #menuToggle .bar:first-child {
        transform-origin: 0% 0%;
    }


    #menuToggle .bar:nth-last-child(2) {
        transform-origin: 0% 100%;
    }


    #menuToggle input:checked~.bar {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: var(--white);
    }


    #menuToggle input:checked~.bar:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }


    #menuToggle input:checked~.bar:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    #menu {
        position: absolute;
        width: 300px;
        margin: -100px 0 0 -50px;
        padding: 50px;
        padding-top: 125px;
        border-radius: 0 0 2rem 0;

        background: var(--black);
        list-style-type: none;

        transform-origin: 0% 0%;
        transform: translate(-100%, 0);

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }

    #menu li {
        padding: 10px 0;
        font-size: 22px;
    }

    #menu{
        display: flex;
        flex-direction: column;
    }

    .link-wrapper{
        text-align: left;
    }

    #menuToggle input:checked~ul {
        transform: none;
    }


    /* PC classes */

    .header-container{
        position: fixed;
        z-index: 1001;
        top: 10px;
        left: 10px;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;

        background-color: var(--black);
        border-radius: 1rem;
    }

    ul {
        display: block;
        flex-direction: none;
        background-color: var(--black);
    
        text-decoration: none;
    }

}