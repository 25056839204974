@import '../../styles/Colors.css';

.section-parent{
    background-image: url('../../../public/images/home-img.png');
    background-position: bottom;
    background-size: cover;

    min-height: 90vh;
    position: relative;
}

.title-container{
    display: flex;
    flex-direction: column;

    padding-left: 5rem;
    padding-top: 10rem;
    width: fit-content;

    color: var(--white);

    user-select: none;
}

.title-wrapper{
    padding:1rem;
}

.btn-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 0.5rem;
    padding: 1rem;

    animation: fadeIn 3s forwards;
}

.title-wrapper h2 span,
.title-wrapper h1 span,
.title-wrapper h4 span {
    opacity: 0;
    animation: fadeIn 3s forwards;
}

.hidden {
    opacity: 0;
}




@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 930px){
    .title-container{
        padding: 0;
    } 
}