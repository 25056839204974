@import '../../styles/Colors.css';



.container-resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 1rem;
}

.wrapper-resume{
    display: flex;
    flex-direction: row;

    max-width: fit-content;
    border-radius: 16px;
    box-shadow: inset #e0e0e081 0px 0px 50px -25px;
    background-color: var(--black);
    margin-top: 1.5rem;
    padding: 4rem 0 0 0;
}

.container-col{
    max-width: 30rem;
    padding: 0 3rem;
}

.wrapper-col {
    padding: 2rem 0 2rem 0;
}

.wrapper-skill {
    display: flex;
    flex-direction: row;

    gap: 1rem;
}

.skill-row{
    width: 40vh;
    font-family: 'Roboto', sans-serif;
    color: var(--white);
}

.skill {
    margin-bottom: 35px;
    position: relative;
    overflow-x: hidden;
}

.skill > p {
    font-size: 1rem;
    font-weight: 700;
    color: var(--white);
    margin: 0;
}

#code-skill{
    padding-top: 18px
}

.skill:before {
    width: 100%;
    height: 5px;
    content: "";
    display: block;
    position: absolute;
    background: var(--white);
    bottom: 0;
}

.skill-bar {
    width: 0;
    height: 5px;
    background: var(--blue);
    display: block;
    position: relative;
}

.skill1 .skill-count1 {
    right: 0;
    
}

.skill-bar span {
    position: absolute;
    border-top: 5px solid var(--blue);
    top: -30px;
    padding: 0;
    font-size: 18px;
    padding: 3px 0;
    font-weight: 500;
}

.wrapper-hobbies {
    display: flex;
    flex-direction: row;
}

.icon-border {
    border: var(--white) 3px solid;
    border-radius: 50%;
    padding: 1rem;
    margin: 0.5rem;
    position: relative;
    overflow: hidden;
}

.icon-border * {
    height: 31px;
    width: 31px;
}

.container-wrapper {
    display: flex; /* Zabere pouze potřebný prostor */
    justify-content: center;
    align-items: center;
    overflow: hidden;

    width: 10rem;
    height: 10rem;

    padding: 1.5rem;
    border-radius: 12px;
    position: relative;

}

.container-wrapper::before{
    content: '';
    background-image: conic-gradient(var(--blue) 20deg, transparent 120deg);
    width: 200%;
    height: 200%;
    position: absolute;

    top: -50%;
    left: -50%;
    animation: rotate 3s linear infinite;
    border-radius: 12px; /* Zaoblené rohy podle kontejneru */ 
}



.container-hobbies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: inset #e0e0e081 0px 0px 50px -25px;
    background-color: var(--black); /* Barva pozadí */
    border-radius: 8px; /* zaoblení vnitřního borderu */
    width: 97%; /* šířka animovaného borderu*/
    height: 97%; /* výška animovaného borderu*/
}

.container-col-small-res{
    display: flex;
    flex-direction: row;
}

@keyframes rotate {
    to {
        transform: rotate(-360deg);
    }
}



.container-xp{
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    gap: 1rem;
}
.wrapper-xp{
    display: flex;
    flex-direction: column;
}

.circle{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    background-color: var(--blue);
    color: var(--black);
    font-weight: 600;
}

.verti-line{
    width: 1px;
    height: 2rem;
    margin:  10px 1.45rem;
    background-color: var(--white);
}


@media (max-width: 1263px){
    .container-col-small-res{
        flex-direction: column;
    }
    .container-col{
        padding-right: 0;
    }
}

@media (max-width: 930px) {

    .container-resume{
        justify-content: center;
        align-items: center;
    }

    .main-resume{
        padding-top: 0 ;
        padding-bottom: 15rem;
    }

    .wrapper-resume{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0 0;
    }

    .container-col {
        padding-left: 2rem ;
    }

    .wrapper-skill {
        width: 30vh;
        font-family: 'Roboto', sans-serif;
        color: var(--white);
    }

    .wrapper-col {
        padding-bottom: 6rem;
    }

    #hobbies-mobile{
        display: flex;
        justify-content: center;
        padding-right: 2rem ;
    }
}

.absolute-description{
    position: absolute;
    color: var(--black);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 21px;
    border-radius: 6px;
    padding: 0 6px;
}

[hobby-type="one"]{
    bottom: 105px;
    left: 27px;
    background-color: var(--white);
}
[hobby-type="two"]{
    bottom: 105px;
    right: 26px;
    background-color: var(--white);
}
[hobby-type="three"]{
    bottom: 20px;
    left: 25px;
    background-color: var(--white);
}
[hobby-type="four"]{
    bottom: 20px;
    right: 20px;
    background-color: var(--white);
}


