@import '../../../styles/Colors.css';

.container-card {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1D1D1D;
    width: calc(325px * 1.05);
    height: calc(325px * 1.05);
    border: 3px solid var(--white);
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    
    z-index: 1;
}

.rotated-border {
    position: absolute;
    top: -8px;
    left: -5px;
    width: calc(100% * 1.02);
    height: calc(100% * 1.02);
    border: 4px solid var(--blue);
    transform: rotate(3deg);
    z-index: -1;
    box-shadow: 0 0 20px rgba(30, 136, 229, 0.8);
}


.card form {
    margin-top: 1.5rem;
    text-align: center;
}

.card form input {
    color: var(--white);
    letter-spacing: 0.5px;
    font-size: 16px;
    background-color: var(--black);
    padding: 8px 7px;
    margin: 0.3rem 0 0 0;
    border: none;
}

.card form textarea {
    font-family: 'Roboto', sans-serif;
    color: var(--white);
    letter-spacing: 0.5px;
    font-size: 16px;
    background-color: var(--black);
    margin: 0.3rem 0 1.5rem 0;
    padding: 8px 7px;
    border: none;
    width: 12.5rem;
    height: 4rem;
}

input:focus {
    outline: 2px solid var(--blue);
}

textarea:focus {
    outline: 2px solid var(--blue);
}


textarea::-webkit-scrollbar {
    width: 6px;
}

textarea::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 6px;
    cursor: auto;
}

textarea::-webkit-scrollbar-track {
    background-color: var(--black);
}