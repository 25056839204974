.custom-shape-divider-bottom-1712580221 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 0; 
}

.custom-shape-divider-bottom-1712580221 svg {
    position: relative;
    display: block;
    width: calc(400% + 1.3px);
    height: 24rem;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1712580221 .shape-fill {
    fill: #1D1D1D;
}


body {
    position: relative;
    padding-bottom: 100px;
    min-height: 200vh;
}


@media (max-width: 930px){
    .custom-shape-divider-bottom-1712580221{
        z-index: -1;
        background-color: #1D1D1D;
    }
}
