.btn-linear{

    font-size: 1.33rem;
    font-weight: 300;

    padding: 8px 35px;
    cursor: pointer;
    position: relative;
}


.btn-l {
    background: none;
    border: 2px solid var(--white);
    color: var(--white);
    transition: color 400ms linear;
    border-radius: 16px;
    position: relative;

    overflow: hidden;

}

.btn-l:hover {
    color: var(--white);
    box-shadow: 0 5px 15px #5bade8;
    border: 2px solid var(--blue);
    border-radius: 0;
    transition: 500ms;
}

.btn-l::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--blue);
    
    z-index: 0;
    transition: transform 0.5s;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}

.btn-l::before {
    transform: scaleX(0);
}

.btn-l:hover::before {
    transform: scaleX(1);
}

.btn-text {
    position: relative;
    z-index: 1;
}



