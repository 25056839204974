.btn{

    font-size: 1.33rem;
    font-weight: 300;

    padding: 8px 35px;

    background-color: transparent;
    border: #f8f8f8 solid 2px;
    border-radius: 16px;
    color: #f8f8f8;

    transition: 500ms;
}

.btn:hover{
    cursor: pointer;
    color:#5bade8;
    border-color: #5bade8;
    background-color: var(--blue);
    border-radius: 0;

    color: #f8f8f8;
    box-shadow: 0 5px 15px #5bade8;

    transition: 500ms;
}


