@import '../../styles/Colors.css';

.container-about {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    padding-top: 3rem;
    border-radius: 12px;
    background: linear-gradient(90deg, rgba(91,173,232,0) 40%, rgba(91,173,232,0.3338469176733193) 100%);
}



.wrapper-about-title {
    display: block;
    padding-bottom: 4rem;
}

.wrapper-about-content {
    width: 55%;
    padding-right: 15px;
    padding-bottom: 5rem;
}

.my-line {
    border: none;
    background-color: var(--blue);

    height: 3px;
    width: 3rem;
}

.container-footer {
    display: flex;
    justify-content: center;

    padding-top: 5rem;
    padding-right: 10rem;
}

.wrapper-footer {
    display: flex;
    align-items: center;
    text-align: center;

    gap: 2rem;
}

.portrait {
    height: 90vh;
    width: auto;
}

.container-portrait {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10rem;
}



@media (max-width: 1200px){


    .container-portrait {
        right: 0;
        overflow: hidden;
    }
}


@media (max-width: 930px) {

    .wrapper-about-title {
        text-align: center;
        justify-content: center;
    }

    #about-padding{
        padding: 0 30px 0 30px;
        
    }

    .wrapper-about-content {
        width: 100%;
        text-align: center;
        padding: 0;
        margin-bottom: 80px;
    }

    .container-footer {
        padding: 5rem 0 3rem 0;

    }

    .container-about {
        flex-direction: column;
        padding: 3rem 0 0 0;
        margin: 0 1rem;
        background: none;
    }

    .portrait-flex{
        display: flex;
        justify-content: center;
    }

    .portrait {
        position: absolute;
        z-index: 1;
        scale: 140%;
        padding: 0 6rem 70px;
        margin-top: 3rem;
        height: 300px;
    }

    .container-portrait {
        position: relative;
        width: 300px;
        height: 300px;
        overflow: hidden;
        border-radius: 100%;
    }

    .container-portrait::before{
        content:' ';
        position: absolute;
        height: 100%;
        width: 190px;
        background-color: var(--white);
        animation: animate 5000ms linear infinite;
    }

    .container-portrait::after{
        content: ' ';
        position: absolute;
        inset:8px;
        background-color: var(--black);
        border-radius: 100%;
    }

    .container-outline {
        position: relative;
        width: 300px;
        height: 300px;
        overflow: hidden;
        padding: 20px;
        border-radius: 100%;
    }

    .container-outline::before{
        content:' ';
        position: absolute;
        z-index: -2;
        height: 500px;
        width: 150px;
        left: 30%;
        bottom:-20px;
        background-color: var(--blue);
        animation: rev-animate 5000ms linear infinite;
    }

    .container-outline::after{
        content: ' ';
        position: absolute;
        background-color: var(--black);
        z-index: -1;
        inset: 2px;
        border-radius: 100%;
    }



    @keyframes animate{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }


    @keyframes rev-animate{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(-360deg);
        }
    }

}