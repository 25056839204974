@import '../../styles/Colors.css';

.process-title-container{
    text-align: center;
}

.process-wrapper{
    max-width: 80vh;
    margin: 50px 0 0 0;
    text-align: left;
    
}

.process-sub-container{
    display: flex;
    padding: 0 30px;
    justify-content: center;
    justify-content: center;
}

.process-container{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.icon-absolute{
    position: absolute;
    right: -14px;
    background-color: var(--black);

}

.progress-scroll-ball{
    background-color: var(--blue);
    border-radius: 100%;
    max-width: 80px;
    max-width: 80px;
}

.progress-scroll {
    position: absolute;
    z-index: 0;
    top: -30px;
    right: 295px;
    width: 5px;
    height: 200%;
    background-color: var(--black);
}   

.progress-thumb{
    width: 100%;
    background-color: var(--blue);
    border-radius: 25px;
}

.shadow-box {
    box-shadow: 0 8px 16px var(--blue);
    transition: 100ms;
  }

@media (max-width: 1470px){
    .progress-scroll {
        height: 200%;
        right: 30px;
    }   
}

@media (max-width: 930px){
    .progress-scroll {
        height: 201%;
        right: 25px;
    }   
}

@media (max-width: 650px){
    .progress-scroll {
        height: 195%;
    }   
}

@media (max-width: 620px){
    .progress-scroll {
        height: 190%;
    }   
}



@media (max-width: 565px){
    .progress-scroll {
        height: 185%;
    }   
}

@media (max-width: 560px){
    .progress-scroll {
        height: 180%;
    }   
}

@media (max-width: 480px){
    .progress-scroll {
        height: 175%;
    }   
}

@media (max-width: 455px){
    .progress-scroll {
        height: 170%;
    }   
}

@media (max-width: 430px){
    .progress-scroll {
        height: 165%;
    }   
}

@media (max-width: 414px){
    .progress-scroll {
        height: 138%;
        right: 25px;
    }   

    .process-wrapper{
        text-align: center;
        
    }
}



